<template>
    <div class="section">
        <div class="card mt-3">
            <div class="card-header has-background-black">
                <div class="card-header-title has-text-white">Telegram bot ayarları</div>
            </div>
            <div class="card-content">
                <b-field label="PPX">
                    <b-switch size="is-small" v-model="ppx">{{ ppx ? 'Aktif' : 'Pasif' }}</b-switch>
                </b-field>

                <b-field label="VeParam">
                    <b-switch size="is-small" v-model="veparam">{{ veparam ? 'Aktif' : 'Pasif' }}</b-switch>
                </b-field>
                <b-button type="is-success" class="is-align-self-flex-end" @click="save">Güncelle</b-button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { SettingService } from '@/services'
export default {
    name: 'Setting',
    mounted() {
        SettingService.edit(1).then(res => {
            this.veparam = res.data.telegram.veparam
            this.ppx = res.data.telegram.ppx
        })
    },
    data() {
        return {
            veparam: false,
            ppx: false
        }
    },
    methods: {
        save() {
            SettingService.update({
                id: 1,
                telegram: {veparam: this.veparam, ppx: this.ppx}
            })
                .then(() => {
                    this.$buefy.toast.open({
                        message: 'Güncellendi',
                        type: 'is-success'
                    })
                })
                .catch(err => this.$buefy.toast.open({ message: err.message, position: 'is-top-right', type: 'is-danger' }))
        }
    }
}
</script>

<style>
.transfer-groups>.field {
    margin: 0
}
</style>